import "./App.css";
import "./assets/css/analys360.css";
import "./assets/css/mainComponents.css";
import "./assets/css/hausrat.css";
import "./assets/css/krankenkasse.css";
import "./assets/css/mainComponents.css";
import "./assets/css/pension.css";
import "./assets/css/plus.css";
import "./assets/css/uberUns.css";
import "./assets/css/versicherungen.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import React, { lazy, Suspense } from "react";

import MoonLoader from "react-spinners/ClipLoader";

const Header = lazy(() => import("./Header"));
const Footer = lazy(() => import("./Footer"));

const Rechtsschutz = lazy(() => import("./components/Rechtsschutz"));
const ToggleDarkMode = lazy(() => import("./components/ToggleDarkMode"));
const ScrollTop = lazy(() => import("./ScrollTop.js"));

function App() {
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("darkModeKey")) !== true &&
      JSON.parse(localStorage.getItem("darkModeKey")) !== false
    ) {
      localStorage.setItem("darkModeKey", "false");
    }
    setDarkMode(localStorage.getItem("darkModeKey"));
  }, []);

  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkModeKey"))
  );
  useEffect(() => {
    if (darkMode === true) {
      document.body.classList.add("darkTheme");
    } else {
      document.body.classList.remove("darkTheme");
    }
    localStorage.setItem("darkModeKey", JSON.stringify(darkMode));
    setDarkMode(JSON.parse(localStorage.getItem("darkModeKey")));
  }, [darkMode]);

  return (
    <div className="App">
      <>
        <BrowserRouter>
          <ScrollTop>
            <Suspense
              fallback={
                <div className="loaderStyle">
                  <MoonLoader size={100} color="#61549c" />
                </div>
              }
            >
              <Header darkMode={darkMode} />
              <ToggleDarkMode darkMode={darkMode} setDarkMode={setDarkMode} />
              <Rechtsschutz darkMode={darkMode} />
              <Footer />
            </Suspense>
          </ScrollTop>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
